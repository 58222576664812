import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class NotAuthorizedComponent extends Vue {
    $refs = {
        notAuthorized: null,
    };

    created() {
        Debug.setDebugModule('notAuthorized', this);
        document.body.style.backgroundImage = 'url(/images/White.jpg)';
     
    }

    beforeDestroy() {
        document.body.style.backgroundImage = "";
    }

    mounted() {
    }
}
